<template>
  <main class="mian">
    <div class="diary-container">

      <header>
        <p>{{ $route.query.time }}</p>
        <h1>{{ $route.query.title }}</h1>
        <p v-show="$route.query.other">{{ $route.query.other }}</p>
        <i class="el-icon-close" @click="$router.back()"></i>
      </header>
      <div class="markdown-body">
        <component v-bind:is="$route.query.md"></component>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "bookDetail"
};
</script>
<style lang="scss" scoped>
.markdown-body {
  font-size: 1.1em;
}

main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 16px;
  overflow-y: auto;
}

.diary-container {
  max-width: 1024px;
  width: 100%;
  margin: 20px auto;
  padding: 0 25px;
  box-sizing: border-box;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
  font-weight: 100;
  font-size: 3rem;
  position: relative;

  h1 {
    font-size: 1em;
    padding-bottom: 30px;
    border-bottom: solid 2px #0000000f;
    width: 100%;
  }

  i {
    font-size: 0.5em;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  i:hover {
    transform: scale(1.2);
  }

  i:active {
    transform: scale(1);
  }

  p {
    font-size: 0.3em;
    margin: 10px 0;
    opacity: 0.5;
  }

  * {
    text-align: center;
    font-weight: normal;
    margin: 0;
  }
}

@media only screen and (max-width:820px) {
  header {
    margin: 30px 0;
    align-items: flex-start;

    * {
      text-align: left;
    }
  }
}

@media only screen and (max-width: 600px) {

  .markdown-body {
    font-size: 1em;
  }

  header {
    margin: 10px 0 30px 0;
    font-size: 2rem;
    align-items: flex-start;


    h1 {
      padding-bottom: 10px;
    }

    p {
      font-size: 0.5em;
    }

    * {
      text-align: left;
    }
  }
}
</style>
<style lang="scss">
.markdown-body * {
  line-height: 1.5;
}

.button {

  span,
  i {
    background: transparent;
  }
}
</style>